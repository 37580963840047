.root {
  height: 100%;
  overflow: hidden;
  position: relative;
  border: none;
  box-shadow: none;

  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }

  button: {
    width: 200px;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;

  p {
    margin: 0;
  }

  section {
    display: flex;
    gap: 10px;
  }
}
