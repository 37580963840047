.root {
  grid-column: span 12;
}

.selectBox {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 16px;
}

.select {
  flex-grow: 1;
}
