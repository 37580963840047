.root {
  position: relative;
  display: flex;
  flex-grow: 1;
  height: 100vh;
  overflow-y: hidden;
  background-color: var(--mantine-color-gray-1);
}

.main {
  width: 100vw;
}
