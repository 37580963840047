.label {
  font-weight: 400;
  font-size: 16px;
  color: #66788a;
}

.block {
  padding: 8px;
  color: #66788a;
  background-color: #e8ecf0;
  border-radius: 7px;
  font-size: 14px;

  &.btnText {
    margin-top: 49px;
    padding: 8px 30px;
    width: fit-content;
  }
}
