.typeRadioGroup {
  div[role='radiogroup'] {
    display: flex;
    gap: 20px;
  }
}

.visibilityBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
}
