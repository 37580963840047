.mantine-Table {
  &-thead {
    background-color: var(--mantine-color-gray-2);

    &.sticky {
      position: sticky;
      top: 0;
    }
  }
}
