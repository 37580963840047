.root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 96vh;
  overflow-y: hidden;
}

.paper.paper {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-height: 100%;
  height: 100%;
}

.multiValue {
  display: flex;
  flex-wrap: wrap;
  max-width: 40vw;
  cursor: default;

  > span {
    white-space: nowrap;
    font-size: var(--mantine-font-size-xs);
    border-radius: 5px;
    background-color: var(--mantine-color-gray-1);
    border-radius: 5px;
    border: 1px solid var(--mantine-color-gray-5);
    padding: 0 5px;
    margin: 3px;
  }
}
