.root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 96vh;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.tabPanel {
  padding: 24px 0 34px 0;
  height: inherit;
  overflow: scroll;
}
