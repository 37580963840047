.dropzone {
  border: 2px dashed var(--mantine-color-gray-4);
}

.thumb {
  position: relative;
  figcaption: {
    overflowwrap: break-word;
  }
}

.deleteButton {
  position: absolute;
  right: 5px;
  top: 5px;
}
