.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 216px;
  max-width: 216px;
}

.resetBtn {
  color: var(--mantine-primary-color-9);

  &:hover {
    color: var(--mantine-primary-color-7);
  }
}

.pageSize {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  max-width: fit-content;

  .select {
    width: 80px;

    input {
      padding-right: 20px;
    }
  }
}
