.group {
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
  row-gap: 24px;
}

.dateRange {
  input {
    height: 39px;
    border-color: var(--mantine-color-gray-9);

    &:hover,
    &:active {
      border-color: var(--mantine-color-gray-9);
    }

    ::placeholder {
      color: var(--mantine-color-gray-6);
      font-size: 16px;
    }
  }

  button[data-in-range] {
    color: #fff !important;
  }
}

.resetBtn {
  color: var(--mantine-primary-color-9);

  &:hover {
    background-color: transparent;
    color: var(--mantine-primary-color-7);
  }
}

