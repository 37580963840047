.mantine {
  &-Radio {
    &-root {
      --label-cursor: pointer;
      --mantine-cursor-type: pointer;
    }
  }

  &-RadioGroup {
    &-label {
      margin-bottom: 8px;
    }
  }
}
