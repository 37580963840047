.root {
  border: 1px solid #EDF1F3;
  padding: 16px 14px;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.select {
  max-width: 584px;
}
