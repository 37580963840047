.root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  width: 100%;
  padding-bottom: 22px;
  font-weight: 500;
  font-size: 14px;
}

.group {
  width: inherit;
  justify-content: space-between;
  align-items: start;
}

.selectWrapper,
.textInput,
.textArea {
  width: 526px;
}

.text {
  grid-column: span 8;
}

.textArea {
  grid-column: span 12;

  textarea: {
    min-height: 114px;
  }
}

.label {
  font-weight: 500;
}
