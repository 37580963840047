.paper {
  border: 1px solid #e8ecf0;
  width: 352px;
  flex-shrink: 0;
  background: #f6f8f8;
  height: fit-content;
}

.imgWrapper {
  max-width: 77px;
  max-height: 96px;
  width: 100%;
  border-radius: 4px;
  height: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #e8ecf0;
}

.uuid {
  text-align: end;
  margin-left: auto;
  color: #66788a;
  margin-top: 4px;
}

.emptyImage {
  background: #e8ecf0;
  width: 77px;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
