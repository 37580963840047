.root {
  display: flex;
  align-items: center;
  margin: 8px;
}

.handle {
  cursor: pointer;
  width: 24px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.content {
  flex-grow: 1;
}
