.root {
  width: 99%;
  border: 1px solid #e8ecf0;
  border-radius: 4px;
  padding: 10px 12px 12px 12px;
  cursor: pointer;
  display: flex;
  margin-bottom: 4px;
  margin-top: 8px;

  :global(.mantine-Group-root) {
    margin-left: auto;
  }
}
