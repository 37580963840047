.root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 96vh;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.actions {
  display: flex;
  gap: 10px;
}

.actionBtn {
  width: 200px;
}

.tabs {
  height: 100%;
}

.tabsList {
  border-bottom: none;
}
