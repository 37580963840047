.datepicker {
  input {
    height: 39px;
    border-color: var(--mantine-color-gray-9);
    &:hover,
    &:active {
      border-color: var(--mantine-color-gray-9);
    }
    ::placeholder {
      color: var(--mantine-color-gray-9);
      font-size: 16px;
    }
  }

  button[data-in-range] {
    color: #fff !important;
  }
}

.resetBtn {
  color: var(--mantine-primary-color-9);

  &:hover {
    color: var(--mantine-primary-color-7);
  }
}

.pageSize {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  max-width: fit-content;

  .select {
    width: 80px;

    input {
      padding-right: 20px;
    }
  }
}
