.root {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 96vh;
  overflow-y: hidden;

  form {
    max-height: calc(100vh - 32px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  .submitButton {
    width: 200px;
  }
}

.head {
  margin-bottom: 16px;
  width: 100%;
}

.formContent {
  max-height: calc(100vh - 32px);
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.paperWrapper {
  overflow: scroll;
}

.root .paper {
  overflow: scroll;
  padding: 24px;
  padding-bottom: 12px;
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 32px;

  // [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
  //   gridTemplateColumns: 'auto',
  //   gap: 12,
  // },

  > *:nth-of-type(even) {
    margin-bottom: 24px;
  }
}
