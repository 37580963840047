.tooltipEditor {
  display: flex;
}

.tooltipEditorInput {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: 0;
}

.tooltipEditorExternalControl {
  height: 24px;
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--mantine-radius-sm);

  &[data-active] {
    background-color: var(--mantine-color-background);
    border-color: var(--mantine-color-border);
    color: var(--mantine-color);
    // ...theme.fn.hover({ background: colors.hover }),
  }
}

.tooltipEditorSave {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
