.root {
  position: relative;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  form {
    display: flex;
    gap: 72px;
    // [`@media (max-width: ${BREAKPOINTS.md}px)`]: {
    //   flexDirection: 'column',
    //   gap: 12,
    // },
  }
}

.actionButton {
  width: 200px;
}

.actionWrapper {
  display: flex;
  gap: 10px;
}

.formWrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  position: relative;
}
