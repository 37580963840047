.root {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  padding-right: 2px;
  fill: #bfcedb;
  transition: all 0.25s ease-in-out;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 2px;
    height: 100%;
    border-radius: 2px;
    background-color: transparent;
    transition: all 0.25s ease-in-out;
  }

  svg {
    flex-shrink: 0;
    color: #bfcedb;
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    * {
      color: var(--mantine-primary-color-7);
    }
    :after {
      background-color: var(--mantine-primary-color-7);
    }
  }

  &.active {
    fill: #322fc6;
    color: #322fc6;

    &::after {
      background-color: #322fc6;
    }

    svg {
      color: #322fc6;
    }
  }
}

.label {
  line-height: 1.125;
  padding-bottom: 2px;
  color: var(--mantine-color-black);

  .active & {
    color: var(--mantine-primary-color-0);
  }

  &:hover {
    color: var(--mantine-primary-color-7);
  }
}
