.content {
  padding: 12px 0 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}
