.root {
  width: 99%;
  border: 1px solid #e8ecf0;
  border-radius: 4px;
  padding: 10px 12px 12px 12px;
  cursor: pointer;
  display: flex;
  margin-bottom: 4px;
  margin-top: 8px;
  color: #121212;
  background: white;

  &.unvisible {
    color: #8da2b5;
    background: #f0f1f2;
  }
}
