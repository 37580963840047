.trigger {
  position: relative;
  display: block;
  width: 100%;
  max-width: 100%;
  padding-right: 2px;
  fill: #bfcedb;
  transition: all 0.25s ease-in-out;
  box-sizing: border-box;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 2px;
    height: 100%;
    border-radius: 2px;
    background-color: transparent;
    transition: all 0.25s ease-in-out;
  }

  svg {
    flex-shrink: 0;
    color: #bfcedb;
    transition: all 0.25s ease-in-out;
  }

  &:hover {
    * {
      color: var(--mantine-primary-color-7);
    }
  }
}

.radioBlock {
  position: absolute;
  border: 1px solid #e8ecf0;
  border-radius: 4px;
  background: #ffffff;
  z-index: 100;
  padding: 10px 22px 22px 22px;
  bottom: 70px;

  button {
    position: absolute;
    top: 0;
    right: 0;
  }

  [role='radiogroup'] {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  :global(.mantine-Radio-root) {
    border-bottom: 1px solid #e8ecf0;
    padding-bottom: 10px;
    width: 240px;

    &:first-of-type {
      padding-top: 10px;
    }

    &:last-of-type {
      border-bottom: none;
      padding-bottom: 0;
    }
  }
}

.radioGroup {
  gap: 20px;
}
