.content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  min-height: 450px;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

.titleBox {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.typeRadioGroup {
  div[role='radiogroup'] {
    display: flex;
    gap: 20px;
  }
}

.visibilityBox {
  width: 100%;
  display: flex;
  align-items: flex-start;
  gap: 24px;
}
