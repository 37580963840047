.root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
  height: fit-content;

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}
