.content {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.title {
  position: absolute;
  top: 20px;
  left: 20%;
  text-align: center;
}

.btnWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.btn {
  width: 100%;
}
