.root {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  height: 100%;
  max-height: 100%;
  flex-grow: 1;
}

.toolbar {
  top: -49px;

  button[data-active] {
    color: white;
  }
}

.content {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}
