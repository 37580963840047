.root {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.bottomBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: var(--mantine-font-size-xs);
}

.counter {
  color: var(--mantine-color-gray-6);
}
