.field {
  min-width: 272px;
  max-width: 272px;
}

.datePicker {
  input {
    height: 39px;
    border-color: var(--mantine-primary-color-9);

    &:hover,
    &:active {
      color: var(--mantine-primary-color-9);
    }
    ::placeholder {
      color: var(--mantine-primary-color-9);
      font-size: 16px;
    }
  }

  button[data-in-range] {
    color: #fff !important;
  }
}

.resetBtn {
  color: var(--mantine-primary-color-9);

  &:hover {
    background-color: transparent;
    color: var(--mantine-primary-color-7);
  }
}

.select {
  width: 80px;

  input {
    padding-right: 20px;
  }
}
