.control {
  display: flex;
  align-items: center;
  gap: 6px;
  width: 100%;
}

.icon {
  width: 20px;
  height: 20px;
  border-radius: 20px;
}
