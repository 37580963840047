.modal {
  overflow: in;

  &.disabled {
    * {
      cursor: not-allowed;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  position: relative;
  gap: 20px;
}

.radioGroup {
  div[role='radiogroup'] {
    cursor: pointer;
  }

  div > * {
    cursor: pointer;
  }
}
