.field {
  min-width: 272px;
  max-width: 272px;
}

.resetBtn {
  color: var(--mantine-primary-color-9);

  &:hover {
    background-color: transparent;
    color: var(--mantine-primary-color-7);
  }
}

.select {
  width: 80px;

  input {
    padding-right: 20px;
  }
}
