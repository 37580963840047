.trigger {
  position: relative;
  width: 100%;
  fill: #bfcedb;
  transition: all 0.25s ease-in-out;

  &:hover {
    color: var(--mantine-primary-color-7);
  }
}
