.root {
  max-width: 266px;
}

.label {
  .required {
    color: red;
  }
}

.image {
  max-width: 266px;
  padding-top: 16px;
}
