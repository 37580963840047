.root {
  width: 75%;

  form {
    grid-template-columns: repeat(12, 1fr);
    display: grid;
    gap: 24px;
    align-items: center;
    width: 100%;
    border: 1px solid #e8ecf0;
    padding: 12px;
  }
}
