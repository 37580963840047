.tabs {
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 12px;
  background: white;
  border-radius: 8px;
  margin-top: 16px;
}

.panel {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 196px);
}
