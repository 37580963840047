.leftCol {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rightCol {
  border-left: 1px solid #f0f1f2;

  > * {
    min-height: 100vh;
  }
}

.buttonWrapper {
  display: flex;
  flex-direction: column;
  gap: 12px;

  button {
    color: black;
    border: 1px solid #bfcedb;
  }
}
