.root {
  position: relative;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: auto 1fr;
  align-items: flex-start;
  gap: 8px;
  padding-bottom: 0;
}

.rootWithPadding {
  padding-bottom: 40px;
}

.contentCardWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  grid-row: span 2;
  gap: 24px;
}

.dndBtn {
  cursor: grab;
}

