.root {
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100%;
}

.paper {
  gap: 24px;
  height: 100%;
  overflow: hidden;

  > * {
    height: 100%;
    display: flex;
    gap: 24px;
    overflow: auto;
    width: 100%;
  }
}
