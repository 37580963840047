.root1 {
  position: relative;
  margin-top: 24px;
  height: 100%;
  overflow: auto;
}

.select {
  width: 80px;
  input {
    padding-right: 20px;
  }
}

.footer {
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  position: relative;
  align-items: center;
  height: fit-content;
  padding-top: 16px;
}

.total {
  display: flex;
  align-items: center;
}

.pagesBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
  right: 0;
  position: relative;
  top: 0;
}

.pagination {
  justify-content: flex-end;
}
