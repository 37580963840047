.field {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 216px;
  max-width: 216px;

  &.big {
    max-width: 240px;
  }
}

.textInput {
  input {
    height: 40px;
    border-color: #e8ecf0;

    ::-webkit-input-placeholder {
      color: #8493a4;
      options: 0;
      font-size: 16px;
    }
  }
}

.resetBtn {
  color: var(--mantine-primary-color-9);

  &:hover {
    background-color: transparent;
    color: var(--mantine-primary-color-9);
  }
}

.pageSize {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 24px;
  max-width: fit-content;

  .select {
    width: 80px;

    input {
      padding-right: 20px;
    }
  }
}
